<template>
  <AreaContainer>
    <AreaNav 
      :links="links"
    ></AreaNav>
    <AreaContent>
      <router-view></router-view>
    </AreaContent>    
  </AreaContainer>
</template>

<script>
export default {
  name: "ContainersLayout",
  computed: {
    links () {
      
      if (this.$route.name === "containersRental") {

        return [
          {
            route: "containers.Rentals",
            name: this.$tk("Common.General.Back"),
            icon: "arrow-left",
            highlight: false
          }
        ]

      } else {

        return [
          {
            route: "containers.Rentals",
            name: this.$tk("ContainerLayout.Rentals")
          },
        ]
      }
    }
  }
}
</script>
